<template>
  <v-sheet>
    <Dialog :dialog="isSelectEmpDialog" card-text-class="px-4 py-0" :dialogWidth="700">
      <template v-slot:title> <span>Select a Employee ({{monthName}} {{($route.query.year)}}) </span> <v-spacer></v-spacer></template>
      <template v-slot:body>
        <v-container>
          <template>
            <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
              <v-row>
                <v-col md="12" class="py-1">
                  <div class="text-h6">
                    Which employee would you like to create this for?
                  </div></v-col
                >
                <v-col md="12" class="py-1">
                  <TextInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="search-emp"
                    v-model="empSearch"
                    class="mt-0"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Start typing then press enter"
                  >
                  </TextInput>
                </v-col>
                <v-col md="12" class="py-1">
                  <v-list>
                    <v-divider class="my-0"></v-divider>
                    <template v-for="(item, index) in employees" >
                      <v-list-item
                        :key="item.name"
                        v-on:click="routeToDetail(item.id)"

                      >
                        <v-list-item-avatar>
                          <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.first_name }}</v-list-item-title>
                          <v-list-item-subtitle class="text-capitalize">{{ item.role_name }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action-text>
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" style="font-size: 14px">
                                {{ item.nricNumber }}
                              </div>
                            </template>
                            <span>NRIC</span>
                          </v-tooltip>
                        </v-list-item-action-text>
                      </v-list-item>
                      <v-divider v-if="item.length !== 1" :key="index" class="my-0"></v-divider>
                    </template>
                  </v-list>
                </v-col>
              </v-row>
            </v-layout>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <!-- <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="saveRecord()"
        >
          Save
        </v-btn> -->
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="$emit('close', false)"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import moment from "moment-timezone";

export default {
  name: "item-dialog",
  title: "Dialog Item",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    employee: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      excelLoading: null,
      filterProductss:[],
      empSearch: null,
      isSelectEmpDialog: false,
      employeeData: [
        {
          id: 1,
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          name: "Tee li sin",
          designation: "Operation Executive",
          nricNumber: "950206-04-5474",
        },
        {
          id: 2,
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          name: "Lilly",
          designation: "Account Manager",
          nricNumber: "950206-04-5475",
        },
        {
          id: 3,
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          name: "John Doe",
          designation: "Manager",
          nricNumber: "950206-04-5476",
        },
        {
          id: 4,
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          name: "Michel",
          designation: "Project Executive",
          nricNumber: "950206-04-5477",
        },
        {
          id: 5,
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          name: "Mercy",
          designation: "Seo Executive",
          nricNumber: "950206-04-5478",
        },
        {
          id: 6,
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          name: "Johnson",
          designation: "CEO",
          nricNumber: "950206-04-5479",
        },
      ],
    };
  },
  computed: {
    employees() {
      if (this.empSearch) {
        return this.employee.filter((item) =>
          item.first_name.toLowerCase().includes(this.empSearch.toLowerCase())
        );
      } else {
        return this.employee; // Return the full list if no search term
      }
    },
  },
  methods: {
   

    routeToDetail(id) {
      this.$router.push({
        name: "create-salary",
        params: { id },
        query: { t: new Date().getTime(),  year: this.$route.query.year,month: this.$route.params.id },
      });
    },
  },
  created() {
    // Initialize the local copy with the prop data
    this.localEmployees = [...this.employee];
  },
  watch: {
    dialog(params) {
      this.isSelectEmpDialog = params;
      let selMonth = this.$route.params.id;
        let monthNumber = String(selMonth).padStart(2, "0"); // Ensure two-digit format
        this.monthName = moment(monthNumber, "MM").format("MMMM"); // Get full month name

    },
  },
  components: {
    Dialog,
    TextInput,
  },
};
</script>
<style scoped>
.table.table-sm td {
  padding: 0.6rem !important;
  vertical-align: middle;
}

.table.table-sm th {
  background-color: #ede8e8 !important;
}
</style>
